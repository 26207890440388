.MovieClip {
    display: flex;
    flex-direction: row;
    width: 100%;
}

/* override for play button in Videos */
.MovieClip .video-react-button {
    font-size: 1.1vw;
}

.MovieClip .videocard-wrapper {
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
}

/* when selected */
.MovieClip .videocard-wrapper.selectedvideo {
    border: 1px solid gray;
}

.artistrow {
  margin: 0;
  margin-top: 5px;
  text-align: left;
  color: whitesmoke;
  padding: 10px 0 0 0;
}

.artistrow .artistrow__main {
  width: 100%;
  background-color: #D9D9D9;
  display: flex;
}

.artistrow .artistrow__main .artist-container,
.artistrow .artistrow__main .collection-container,
.artistrow .artistrow__main .media-container,
.artistrow .artistrow__main .request-container {
  padding: 10px;
  font-size: 24px;
  line-height: 25px;
  font-weight: bold;
  color: black;
  flex: 1 1 0;
  /* to center vertically */
  height: 100px;
  position: relative;
}


.artistrow .artistrow__main .request-container {
  background-color: #23272a;
  flex: 0 1 auto;
  flex-basis: auto;
}

.artistrow .artistrow__main .content-wrapper {
  /* to center vertically */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.artistrow .artistrow__main .label {
  font-size: 11px;
  line-height: 15px;
  color: black;
  font-weight: normal;
}

.artistrow .artistrow__main .media-container img {
  height: 8vh;
  width: 22vw;
}

.artistrow .artistrow__main .request-container .request-collab {
  margin-top: 21px;
  padding: 0 12px;
  font-size: 11px;
  font-weight: bold;
  color: white;
  height: 32px;
  background-color: #F18736;
}

.upload-collection {
  color: white;

  input {
    color: black;
    margin: 6px;
  }

  input[type="file"] {
    color: white;
  }

  button {
    border: 1px solid blue;
    border: 1px solid blue;
    margin: 10px;
    padding: 7px;
  }

  .upload-collection-button {
    margin-top: 15px;
    padding: 5px 13px;
    background-color: #F18736;
  }

  .section-title {
    margin: 18px 18px 5px 0;
    font-size: 28px;
    font-weight: 800;
  }

  .error {
    color: lightcoral;
  }

  .section {
    margin-top: 12px;
  }

}
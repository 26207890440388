.collection-card {
    width: 200px;
    height: 266px;
    color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #23272a;
}

.collection-card:hover {
  border: 2px solid lightgray;
}

.collection-card .thumbnail-container {
    min-width: 200px;
    min-height: 118px;
    max-height: 122px;
    background-size: cover;
    background-size: 200px;
}

.collection-card .collection-data-container {
    width: 200px;
    height: 149px;
    text-align: left;
    background-color: #424242;
    padding: 9px 0 0 9px;
}

.collection-card .title-container {
    overflow-y: hidden;
    white-space: nowrap;
}

.collection-card .collection-data-container .title {
    margin: 4px 0;
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
}

.collection-card .collection-data-container .creator {
    margin: 8px 0;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;
}

.collection-card .description-container {
    margin: 7px 0;
    width: 187px;
    height: 26px;
}

.collection-card .description-text {
    width: 182px;
    font-size: 12px;
    line-height: 14px;
}

.collection-card .collection-data-container .unit-price {
    margin: 8px 0 8px 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}

.collection-card .collection-data-container .supply {
    margin: 4px 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}
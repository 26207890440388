.PreMash {
    display: flex;
    width: 100%;
    color: white;
    padding: 6px;
}

.PreMash .preview-container {
    width: 50%;
}

.PreMash .selection-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 80px 0 0 30px;
}

.PreMash .flow-wrapper {
    text-align: left;
}

.PreMash .videocard-wrapper {
  margin: 10px;
}

.PreMash .audiocard-wrapper {
  margin: 10px;
}
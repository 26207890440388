.videocard {
    width: 210px;
    min-height: 270px;
    color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    background-color: #424242;
}

.videocard .thumbnail-container {
    min-height: 161px;
}

.videocard .thumbnail-container img {
    height: 161px;
}

.videocard .data-container {
    min-width: 210px;
    text-align: left;
    background-color: #424242;
    padding: 8px 4px 2px 10px;
}

.videocard .data-container p {
    margin: 16px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.videocard .title-container {
    overflow-y: hidden;
    white-space: nowrap;
}

.videocard p.title {
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
}

/* .videocard .creator {
  
}   

.videocard .available-supply {
    
}

.videocard .duration {

} */

.videocard .tags-container a.tag {
    margin: 0 13px 0 0;
}
.visualdiscoveryportal {
  margin: 0;
  margin-top: 5px;
  text-align: left;
  color: whitesmoke;
  padding: 10px 0 0 0;
}

.visualdiscoveryportal .discoveryportal__main {
  
}

.visualdiscoveryportal .discoveryportal__main .header-controller {
  display: flex;
  justify-content: space-between;
}

.visualdiscoveryportal .discoveryportal__main .header-controller .section-title {
  margin: 18px 18px 18px 0;
  font-size: 28px;
  font-weight: 800;
}

.visualdiscoveryportal .discoveryportal__main .header-controller button {
  background-color: #388CA6;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 0 12px;
  height: 32px;
}

.visualdiscoveryportal .discoveryportal__main h2 {
  margin: 20px 0 0 0;
  font-size: 18px;
  font-weight: 800;
}

.visualdiscoveryportal .list {
  width: 100%;
}


.visualdiscoveryportal .discoveryportal__main .left-pane {
  flex-basis: 80%;
}

.visualdiscoveryportal .discoveryportal__main .right-pane {
  flex-basis: 20%;
  font-size: 12px;
  padding: 10px;
}

.visualdiscoveryportal .discoveryportal__main .right-pane .action-items {
  padding: 20px;
  border: 1px dotted gray;
  display: flow-root;
}


.visualdiscoveryportal .artists-details {
  float: left;
  padding: 0 20px 0 0;
  width: 57%;
  color: white;
  text-align: left;
  margin-bottom: 25px;
}

.visualdiscoveryportal .artists-details .artist-avatar {
  float: left;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  background-size: cover;
  /* center the image vertically and horizontally */
  background-position: top center;
  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.visualdiscoveryportal .artists-details .artist-details-wrapper {
  float: left;
  /* width: 84%; */
  color: white;
  text-align: left;
}

.visualdiscoveryportal .artists-details .artist-details-wrapper .artist-details {
  margin-bottom: 12px;
}

.visualdiscoveryportal .artists-details .artist-details-wrapper .artist-name {
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
}

.visualdiscoveryportal .icon-twitter {
  width: 30px;
  height: 20px;
  margin: 0;
  display: inline;
}

.visualdiscoveryportal .icon-instagram {
  width: 30px;
  height: 20px;
  margin: 0;
  display: inline;
}

.visualdiscoveryportal  .collab-request {
  margin-top: 15px;
  padding: 5px 13px;
  background-color: #F18736;
}
.Audios {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Audios .audio-card-container {
    display: flex;
    flex-direction: row;
}

.Audios .audiocard-wrapper {
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid #23272a;
}

.Audios .audiocard-wrapper:hover {
  border: 2px solid lightgray;
}

/* when selected */
.Audios .selectedaudio {
    border: 2px solid white;
}

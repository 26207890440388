.splash {
    /* center vertically and horizontally */
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.splash .mash-logo {
    font-family: 'Gajraj One', serif;
    font-size: 10vw;
    color: whitesmoke;
    text-shadow: 3px 3px gray;
}

.splash .welcome {
    width: 70%;
    color: white;
    text-align: left;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.splash .launcher-wrapper {
    margin-top: 2rem;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}


.Nav {
  background: #23272a;
}

.Nav .logo {
  font-family: 'Gajraj One', serif;
  font-size: 20px;
  color: white;
}

.Nav ul {
  background: #23272a;
}

.Nav .user-avatar {
  float: right;
  width: 23px;
  height: 23px;
  margin-top: 2px;
  margin-left: 10px;
  background-size: cover;
  /* center the image vertically and horizontally */
  background-position: top center;
  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
  border: 1px solid white;
}


.Videos {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* override for play button in Videos */
.Videos .video-react-button {
  font-size: 1.1vw;
}

.Videos .videocard-wrapper {
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #23272a;
}

.Videos .videocard-wrapper:hover {
  border: 2px solid lightgray;
}

/* when selected */
.Videos .selectedvideo {
  border: 2px solid white;
}

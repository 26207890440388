.premint {
  /* display: flex;
  flex-direction: row; */
}

.premint .flow-wrapper {
  width: 100%;
  color: white;
  cursor: pointer;
}

.premint .row {
  display: inline-block;
  margin: 0;
  width: 100%;
}

.premint .row.constituents {
  margin: 0;
}

.premint .row .comment {
  margin: 20px 0;
  color: white;
}

.premint .row .previewer {
  float: left;
  width: 45%;
  margin: 10px;
}

.premint .row.constituents .previewer {
  float: left;
  width: 25%;
  margin: 10px;
}

.premint .row .mashed-details {
  float: left;
  margin: 10px;
  width: auto;
  color: White;
}

.premint .row .field-label {
  font-size: 12px;
  color: White;
  margin-bottom: -7px;
}

.premint .row.constituents .field-label {
  font-size: 10px;
  color: White;
  margin-bottom: -2px;
}

.premint .row .field-value {
  font-size: 21px;
  font-weight: 900;
  color: White;
  margin-bottom: 4px;
}

.premint .row.constituents .field-value {
  font-size: 14px;
  font-weight: 900;
  color: White;
  margin-bottom: 4px;
}

.premint .row .video-details {
  float: left;
  margin: 10px;
  width: auto;
  color: White;
}

.premint .row .more-fields {
  width: auto;
  color: White;
  display: flex;
}

.premint .row .more-fields div {
  margin: 0 20px 0 0;
  width: auto;
}